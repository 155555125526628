<template>
  <div class="contact">
    <div class="section section__main">
      <h1 class="section__title">{{ data.user.contact.title }}</h1>
      <p class="section__description">{{ data.user.contact.description }}</p>
      <div class="steps">
        <div v-for="(step, i) in data.user.contact.steps" :key="i" class="step">
          <div class="step__icon">
            <img :src="getIcon(step.icon)" />
          </div>
          <span class="step__title">{{ step.title }}</span>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="subsection">
        <h2 class="subsection__title">{{ "LinkedIn" }}</h2>
        <a :href="data.user.linkedin" target="_blank">
          <p class="subsection__description">{{ data.user.linkedin }}</p>
        </a>
      </div>
      <div class="subsection">
        <h2 class="subsection__title">{{ "Email" }}</h2>
        <a :href="`mailto:${data.user.email.link}`" target="_blank">
          <p class="subsection__description">{{ data.user.email.title }}</p>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    getIcon(icon) {
      return require(`@/assets/icons/${icon}.png`);
    },
  },
};
</script>
<style lang="scss" scoped>
.dark {
  .section {
    &__main {
      background: var(--c-text-muted);
    }
  }
  .step {
    border-left: 5px solid var(--c-white);
    &__icon {
      background: var(--c-white);
      img {
        filter: none;
      }
    }
  }
}

.contact {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: calc(100vh - 70px - 75.19px - 3rem);
}

.section {
  flex: 1 1 48%;
  max-width: 48%;
  min-height: 20rem;
  border-radius: 20px;
  padding: 0 var(--spacer-lg);
  &__main {
    padding: var(--spacer-lg);
    background: var(--c-light);
    transition: 0.5s ease;
  }
  &__title {
    font-family: var(--font-family-secondary);
    font-weight: var(--font-semibold);
    font-size: 4rem;
    text-transform: uppercase;
  }
  &__description {
    font-size: 1.25rem;
  }
}
.subsection {
  a {
    text-decoration: none;
  }
  &:not(:last-child) {
    margin-bottom: var(--spacer-2xl);
  }
  &__title {
    font-family: var(--font-family-secondary);
    font-weight: var(--font-semibold);
    font-size: 4rem;
    text-transform: uppercase;
    border-bottom: 5px solid;
    line-height: 1;
    margin-bottom: var(--spacer-base);
  }
  &__description {
    color: #898989;
    font-weight: var(--font-medium);
    font-size: 1.75rem;
    cursor: pointer;
    position: relative;
    width: fit-content;
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      background-color: var(--c-gray-variant);
      transform-origin: bottom right;
      transition: transform 0.3s ease-out;
    }
    &:hover {
      cursor: pointer;
      &::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
}

.steps {
  display: flex;
  flex-direction: column;
  margin: var(--spacer-xl) 0 var(--spacer-lg);
}
.step {
  display: flex;
  align-items: center;
  border-left: 5px solid var(--c-text-muted);
  transition: 0.5s ease;
  &:not(:last-child) {
    padding-bottom: var(--spacer-lg);
  }
  &__icon {
    position: relative;
    margin-right: var(--spacer-base);
    background: var(--c-text-muted);
    border-radius: 0 5px 5px 0;
    width: 3.5rem;
    height: 3.5rem;
    transition: 0.5s ease;
    img {
      position: absolute;
      width: 1.75rem;
      height: 1.75rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      filter: invert(1);
      transition: 0.5s ease;
    }
  }
  &__title {
    display: block;
    font-size: 1.35rem;
  }
}
</style>
