<template>
	<div class="a-logo" @click.stop="goTo('/')">
		<div
			class="lt"
			:class="{'a-logo__second': current !== 'lt', 'lt--active': active == 'lt'}"
			@mouseover="current = 'lt'"
			@mouseleave="current = active"
			@click="active = 'lt'"
		>
			<span class="lt__firstname">Laurentiu&nbsp;</span>
			<span class="lt__lastname">Tutu</span>
		</div>
		<!-- &nbsp;
		<div
			class="tc"
			:class="{'a-logo__second': current !== 'tc', 'tc--active': active == 'tc'}"
			@mouseover="current = 'tc'"
			@mouseleave="current = active"
			@click="active = 'tc'"
		>
			<span class="tc__firstname">Ciprian&nbsp;</span>
			<span class="tc__lastname">Trandafir</span>
		</div> -->
	</div>
</template>
<script>

export default {
	data () {
		return {
			current: 'lt',
			active: 'lt'
		}
	},
	methods: {
		goTo (link) {
			this.$router.push(link)
		}
	}
}
</script>
<style lang="scss" scoped>

.a-logo {
	font-size: var(--font-xl);
	font-weight: var(--font-normal);
	cursor: pointer;
	display: flex;
	align-items: center;

	&__second {
		color: #7e7e7e;
	}
}
.lt {
	display: flex;
	align-items: center;
	&--active {
		.lt__firstname {
			max-width: 9ch;
		}
		.lt__lastname {
			max-width: 4ch;
		}
	}
	&:hover {
		.lt__firstname {
			max-width: 9ch;
		}
		.lt__lastname {
			max-width: 4ch;
		}
	}
	&__firstname {
		font-family: var(--font-family-secondary);
		display: block;
		max-width: .8ch;
		overflow: hidden;
		white-space: nowrap;
		transition: .5s ease;
	}
	&__lastname {
		font-family: var(--font-family-secondary);
		display: block;
		max-width: .8ch;
		overflow: hidden;
		white-space: nowrap;
		transition: .5s ease;
	}
}
.tc {
	display: flex;
	align-items: center;
	&--active {
		.tc__firstname {
			max-width: 7ch;
		}
		.tc__lastname {
			max-width: 9ch;
		}
	}
	&:hover {
		.tc__firstname {
			max-width: 7ch;
		}
		.tc__lastname {
			max-width: 9ch;
		}
	}
	&__firstname {
		font-family: var(--font-family-secondary);
		display: block;
		max-width: .9ch;
		overflow: hidden;
		white-space: nowrap;
		transition: .5s ease;
	}
	&__lastname {
		font-family: var(--font-family-secondary);
		display: block;
		max-width: .9ch;
		overflow: hidden;
		white-space: nowrap;
		transition: .5s ease;
	}
}
</style>