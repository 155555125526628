<template>
  <div class="a-button" :class="{ invert: invert }">
    <span :class="{ invert: invert }">{{ title }}</span>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    invert: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.a-button {
  user-select: none;
  width: fit-content;
  outline: none;
  border: 1px solid var(--c-gray-variant);
  border-radius: 50px;
  padding: 0.75rem var(--spacer-base);
  background: none;
  transition: 0.5s ease;
  &:hover {
    border-color: var(--c-dark);
  }
  span {
    display: block;
    width: fit-content;
    margin: 0 auto;
    position: relative;
    font-size: var(--font-base);
    text-transform: uppercase;
    transition: transform 0.25s ease-out;
    line-height: 1.2;
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      background-color: #1d1d1f;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
    &:hover {
      cursor: pointer;
      &::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
    @include for-mobile {
      font-size: var(--font-sm);
    }
  }
  @include for-mobile {
    padding: 0.5rem var(--spacer-base);
  }
}
.invert {
  &:hover {
    border-color: var(--c-white) !important;
  }
  &::after {
    background-color: var(--c-white) !important;
  }
}
</style>
