<template>
  <div class="cards">
    <div v-for="(card, i) in data" :key="i" class="m-service-card card">
      <div class="card__container">
        <div class="card__container--inner">
          <div class="header">
            <div class="header__thumbnail">
              <img :src="getIcon(card.icon)" />
            </div>
            <div class="header__description">
              <h3 class="header__description--title">
                {{ card.title }}
              </h3>
              <div class="header__description--perks">
                <span v-for="(perk, i) in card.perks" :key="i">
                  {{ perk }}
                </span>
              </div>
            </div>
          </div>
          <div class="content">
            <span>
              {{ card.description }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ScrollObserver, valueAtPercentage } from "aatjs";

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.stackCards();
  },
  methods: {
    getIcon(icon) {
      return require(`@/assets/icons/${icon}.png`);
    },
    stackCards() {
      const cardsContainer = document.querySelector(".cards");
      const cards = document.querySelectorAll(".card");
      cardsContainer.style.setProperty("--cards-count", cards.length);
      cardsContainer.style.setProperty(
        "--card-height",
        `${cards[0].clientHeight}px`
      );
      this.$emit("section-title", cards[cards.length - 1].clientHeight);
      Array.from(cards).forEach((card, index) => {
        const offsetTop = 20 + index * 10;
        card.style.paddingTop = `${offsetTop}px`;
        if (index === cards.length - 1) {
          return;
        }
        const toScale = 1 - (cards.length - 1 - index) * 0.1;
        const nextCard = cards[index + 1];
        const cardContainer = card.querySelector(".card__container");
        const cardInner = card.querySelector(".card__container--inner");

        ScrollObserver.Element(nextCard, {
          offsetTop,
          offsetBottom: window.innerHeight - card.clientHeight,
        }).onScroll(({ percentageY }) => {
          cardContainer.style.scale = valueAtPercentage({
            from: 1,
            to: toScale,
            percentage: percentageY,
          });
          cardContainer.style.filter = `brightness(${valueAtPercentage({
            from: 1,
            to: 0.75,
            percentage: percentageY,
          })})`;
          cardInner.style.filter = `blur(${valueAtPercentage({
            from: 0,
            to: 1,
            percentage: percentageY,
          })}px)`;
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.dark {
  .card {
    &__container {
      background: var(--c-gray);
    }
  }
  .header {
    &__thumbnail {
      background: var(--c-white);
      img {
        filter: invert(0);
      }
    }
    &__description {
      &--perks {
        span {
          border: 1px solid var(--c-dark);
          background: var(--c-dark);
          &:nth-child(2) {
            border: 1px solid var(--c-gray-lighten);
            background: var(--c-gray-lighten);
          }
        }
      }
    }
  }
}

.m-service-card {
  position: sticky;
  top: var(--spacer-base);
  transform-origin: center top;
}
.header {
  display: flex;
  align-items: center;
  &__thumbnail {
    position: relative;
    background: var(--c-gray);
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 10px;
    transition: 0.5s ease;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 2.5rem;
      height: 2.5rem;
      filter: invert(1);
      transition: 0.5s ease;
      @include for-mobile {
        width: 1.75rem;
        height: 1.75rem;
      }
    }
    @include for-mobile {
      min-width: 3.5rem;
      width: 3.5rem;
      height: 3.5rem;
    }
  }
  &__description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 5rem;
    margin-left: var(--spacer-base);

    &--title {
      font-size: var(--font-xl);
      font-weight: var(--font-medium);
    }
    &--perks {
      user-select: none;
      display: flex;
      @include for-mobile {
        flex-wrap: wrap;
      }
      span {
        transition: 0.3s ease;
        margin-right: var(--spacer-xs);
        border-radius: 50px;
        border: 1px solid var(--c-white);
        background: var(--c-white);
        padding: var(--spacer-2xs) var(--spacer-sm);
        font-size: var(--font-sm);
        &:hover {
          transform: rotate(-3deg);
        }
        &:nth-child(2) {
          border: 1px solid var(--c-light-darken);
          background: var(--c-light-darken);
        }
        &:nth-child(3) {
          background: transparent;
          border: 1px solid;
        }
        &:last-child {
          @include for-mobile {
            margin-right: 0;
          }
        }
        @include for-mobile {
          margin: var(--spacer-xs) var(--spacer-xs) 0 0;
        }
      }
    }
  }
}
.content {
  margin-top: 4rem;
  span {
    display: block;
    font-size: 1.25rem;
    line-height: 1.4;
    font-weight: var(--font-light);
    @include for-mobile {
      font-size: var(--font-base);
    }
  }
}

.card__container {
  will-change: transform;
  overflow: hidden;
  box-shadow: 0 25px 50px -12px hsla(265.3, 20%, 10%, 35%);
  transform-origin: center top;
  width: 100%;
  min-height: 10rem;
  background: var(--c-light);
  border-radius: 10px;
  padding: var(--spacer-xl);
  margin-bottom: 4rem;
  transition: 0.5s ease;
  @include for-mobile {
    padding: var(--spacer-base);
    margin: 0;
  }
}

.cards {
  width: 100%;
  margin: 0 auto;
}
</style>
