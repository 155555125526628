<template>
  <div class="m-section" :class="{ divider: divider }">
    <div class="container">
      <slot name="left"></slot>
    </div>
    <div class="container">
      <slot name="right"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    divider: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-section {
  max-width: 1400px;
  margin: var(--spacer-xl) auto;
  padding: var(--spacer-base) 0 var(--spacer-lg);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
}
.container {
  flex: 1 1 calc(50% - 1rem);
  @include for-mobile {
    flex: 1 1 calc(100% - 2rem);
    margin: 0 var(--spacer-sm);
  }
}
.divider {
  border-top: 1px solid;
}
</style>
