<template>
  <div class="o-footer">
    <div v-if="!contactPage" class="o-footer__top">
      <h2>{{ "Interested in working together?" }}</h2>
      <span>{{ `Let's discuss on:` }}</span>
      <p>{{ data.user.email.title }}</p>
    </div>
    <div class="o-footer__bottom">
      <div class="section section__left">
        <h4>
          {{ `Front-End Developer: ` }}
          <span>
            {{ `Laurentiu Tutu` }}
          </span>
        </h4>
        <h4>
          {{ `Back-End Developer: ` }}
          <span>
            {{ `Ciprian Trandafir` }}
          </span>
        </h4>
      </div>
      <div class="section__center">
        <AButton
          :title="'LinkedIn'"
          :invert="true"
          class="section__center--item"
          @click.native="external(data.user.linkedin)"
        />
        <div class="menu__item" @click="activeMenu = !activeMenu">
          <img class="menu__item--icon" :src="menuIcon" />
        </div>
        <AButton :title="'CV'" :invert="true" class="section__center--item" />
      </div>
      <div class="section section__right">
        <h4 class="scrollTop" @click="scrollTop()">
          {{ `Back to top` }}
        </h4>
        <h4>
          {{ `&copy; ${currentYear} – ` }}
          <span>
            {{ `LTCT` }}
          </span>
        </h4>
      </div>
    </div>
    <transition name="fade">
      <MMenu
        v-if="activeMenu"
        transition="slide-top"
        :size="16"
        offset="bottom: calc(52px + 5.25rem)"
        @close-menu="activeMenu = false"
      />
    </transition>
  </div>
</template>
<script>
import AButton from "@/components/atoms/a-button";
import MMenu from "@/components/molecules/m-menu";

export default {
  components: {
    AButton,
    MMenu,
  },
  data() {
    return {
      activeMenu: false,
    };
  },
  computed: {
    currentYear() {
      const currentDate = new Date();
      return currentDate.getFullYear();
    },
    menuIcon() {
      return this.activeMenu
        ? require("@/assets/icons/cross.png")
        : require("@/assets/icons/menu.png");
    },
    contactPage() {
      return this.$route.path === "/contact";
    },
  },
  methods: {
    external(link) {
      window.open(link);
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.o-footer {
  position: relative;
  padding-bottom: var(--spacer-base);
  max-width: 1400px;
  margin: var(--spacer-xl) auto 0;

  &__top {
    text-align: center;
    margin-bottom: 8rem;
    h2 {
      font-family: var(--font-family-secondary);
      font-weight: var(--font-semibold);
      font-size: 8rem;
      line-height: 1;
      max-width: 60%;
      margin: 0 auto;
      text-transform: uppercase;
      @include for-mobile {
        font-size: 4rem;
        max-width: 80%;
      }
    }
    span {
      display: block;
      margin: 3rem 0 var(--spacer-xs);
    }
    p {
      font-size: 1.75rem;
      margin: 0;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      @include for-mobile {
        font-size: 1.25rem;
      }
    }
    @include for-mobile {
      margin-bottom: 3rem;
    }
  }
  &__bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    @include for-mobile {
      flex-direction: column;
    }
  }
}

.section {
  flex: 1 1 20%;
  h4 {
    font-size: var(--font-base);
    font-weight: var(--font-normal);
    line-height: 1.6;

    span {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__left {
    text-align: left;
    @include for-mobile {
      text-align: center;
    }
  }
  &__center {
    flex: 1 1 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    &--item {
      margin: var(--spacer-xs);
      padding: 0.5rem var(--spacer-base) !important;
      width: 12rem;
      &::v-deep span {
        font-size: var(--font-sm) !important;
      }
      @include for-mobile {
        width: 8rem;
        margin: var(--spacer-sm) var(--spacer-2xs);
      }
    }
  }
  &__right {
    text-align: right;
    @include for-mobile {
      text-align: center;
    }
  }
}
.menu__item {
  border: 1px solid var(--c-gray-variant);
  border-radius: 50px;
  padding: 0 var(--spacer-sm);
  position: relative;
  font-size: var(--font-xs);
  text-transform: uppercase;
  height: 2.25rem;
  min-width: 2.25rem;
  margin: 0 var(--spacer-sm);
  display: flex;
  align-items: center;
  transition: 0.3s ease;
  cursor: pointer;
  &--icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.25rem;
    height: 1.25rem;
    object-fit: cover;
    transition: filter 0.5s ease;
    filter: none;
  }
  &:hover {
    border: 1px solid var(--c-dark);
  }
}
.dark {
  .menu__item {
    &--icon {
      filter: invert(1);
    }
    &:hover {
      border: 1px solid var(--c-white);
    }
  }
}
.scrollTop {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
