<template>
  <MSection class="o-services" :divider="true">
    <template #left>
      <div class="left sectionTitle">
        <span>
          How can I help
        </span>
      </div>
    </template>
    <template #right>
      <div class="right">
        <MServiceCards
          class="service-cards__card"
          ref="card"
          :data="data.services"
          @section-title="setTitleHeight"
        />
      </div>
    </template>
  </MSection>
</template>
<script>
import MSection from "@/components/molecules/m-section";
import MServiceCards from "@/components/molecules/m-service-cards";
import { ScrollObserver, valueAtPercentage } from "aatjs";

export default {
  components: {
    MSection,
    MServiceCards,
  },
  methods: {
    setTitleHeight(height) {
      const sectionTitle = document.querySelector(".sectionTitle");
      sectionTitle.style.height = `calc(${height}px + 2rem)`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.o-services {
  .left {
    position: sticky;
    top: var(--spacer-base);
    height: 2rem;
    span {
      font-family: var(--font-family-secondary);
      font-size: 4rem;
      font-weight: var(--font-semibold);
      text-transform: uppercase;
      line-height: 1;
      @include for-mobile {
        font-size: 3rem;
      }
    }
    @include for-mobile {
      height: 3rem !important;
    }
  }
  .right {
    // span {
    // 	display: block;
    // 	font-size: 2rem;
    // 	line-height: 1.4;
    // 	margin-bottom: var(--spacer-base);
    // }
  }
}
</style>
