<template>
  <div class="o-header">
    <div class="o-header__container">
      <div class="flex">
        <DarkModeToggler
          class="darkMode"
          @toggle-dark-mode="$emit('toggle-dark-mode')"
        />
        <ALogo />
      </div>
      <div class="menu">
        <div class="menu__item desktop-only" @click="goTo('/work')">
          See my projects
        </div>
        <div class="menu__item menuIcon" @click.stop="activeMenu = !activeMenu">
          <img class="menu__item--icon" :src="menuIcon" />
        </div>
      </div>
    </div>
    <transition name="fade">
      <MMenu
        v-if="activeMenu"
        transition="slide-bottom"
        @close-menu="activeMenu = false"
      />
    </transition>
  </div>
</template>
<script>
import DarkModeToggler from "@/components/atoms/dark-mode-toggler";
import ALogo from "@/components/atoms/a-logo";
import MMenu from "@/components/molecules/m-menu";

export default {
  components: {
    DarkModeToggler,
    ALogo,
    MMenu,
  },
  data() {
    return {
      activeMenu: false,
    };
  },
  computed: {
    menuIcon() {
      return this.activeMenu
        ? require("@/assets/icons/cross.png")
        : require("@/assets/icons/menu.png");
    },
  },
  methods: {
    goTo(link) {
      this.$router.push(link);
      this.$emit("close-menu");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.o-header {
  max-width: 100%;
  overflow: hidden;
  user-select: none;
  &__container {
    max-width: 1400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    height: 70px;

    .logo {
      font-size: var(--font-lg);
      font-weight: var(--font-normal);

      &__second {
        color: #7e7e7e;
      }
    }
    .menu {
      display: flex;
      align-items: center;

      &__item {
        border: 1px solid var(--c-gray-variant);
        border-radius: 50px;
        padding: 0 var(--spacer-sm);
        position: relative;
        font-size: var(--font-xs);
        text-transform: uppercase;
        height: 2.25rem;
        min-width: 2.25rem;
        margin-left: var(--spacer-base);
        display: flex;
        align-items: center;
        transition: 0.3s ease;
        cursor: pointer;
        &--icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 1.25rem;
          height: 1.25rem;
          object-fit: cover;
          transition: filter 0.5s ease;
        }
        &:hover {
          border: 1px solid var(--c-dark);
        }
      }
    }
    @include for-mobile {
      margin: 0 var(--spacer-sm);
    }
  }
}

.dark {
  .o-header {
    background: var(--c-dark);
    transition: background 5s ease;

    .menu__item {
      &--icon {
        filter: invert(1);
      }
      &:hover {
        border: 1px solid var(--c-white);
      }
    }
  }
}
.flex {
  display: flex;
  align-items: center;
}
.darkMode {
  margin-right: var(--spacer-base);
  margin-top: 5px;
}
</style>
