<template>
  <div id="app" class="app" :class="mode === 'dark' ? 'dark' : 'light'">
    <OHeader @toggle-dark-mode="toggle" />
    <router-view/>
    <OFooter />
  </div>
</template>
<script>
import OHeader from '@/components/organisms/o-header'
import OFooter from '@/components/organisms/o-footer'

export default {
  name: 'app',
  components: {
    OHeader,
    OFooter
  },
  data () {
    return {
      mode: 'dark'
    }
  },
  methods: {
    toggle () {
      this.mode == 'dark'
      ? this.mode = 'light'
      : this.mode = 'dark'
    }
  }
}
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-family-primary);
}
.app {
  width: 100%;
  min-height: 100vh;
  background: var(--c-white);
  color: var(--c-text);
  transition: all .5s ease;
}
.dark {
  background: var(--c-dark);
  color: var(--c-white);
}
</style>
