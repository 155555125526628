<template>
  <div class="m-menu" :style="`min-height: ${size}rem; ${offset}`">
    <div class="m-menu__item">
      <div
        v-for="(item, i) in menu"
        :key="i"
        class="item"
        :style="
          `min-height: calc(${size}rem - 2rem); min-width: calc(${size}rem - 2rem)`
        "
      >
        <transition appear :name="transition">
          <span
            @click="item.title !== 'About' ? goTo(item.link) : goToAbout()"
            >{{ item.title }}</span
          >
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    size: {
      type: Number,
      default: 22,
    },
    transition: {
      type: String,
      default: "slide-bottom",
    },
    offset: {
      type: String,
      default: "top: 70px;",
    },
  },
  data() {
    return {
      menu: [
        {
          title: "Home",
          link: "/",
        },
        {
          title: "About",
          link: "/",
          hash: "#about",
        },
        {
          title: "Portfolio",
          link: "/work",
        },
        {
          title: "Contact",
          link: "/contact",
        },
      ],
    };
  },
  methods: {
    goTo(link) {
      this.$router.push(link);
      this.$emit("close-menu");
    },
    goToAbout() {
      this.$router.push({ name: "Home", hash: "#about" });
      this.$emit("close-menu");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.dark {
  .m-menu {
    background: var(--c-white);
  }
}
.m-menu {
  width: 96%;
  margin: 0 2%;
  position: absolute;
  background: var(--c-light);
  // height: 22rem;
  z-index: 999;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(29, 29, 31, 0.2);
  transition: 0.5s ease;

  &__item {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    overflow: hidden;
    @include for-mobile {
      flex-direction: column;
    }
  }
  @include for-mobile {
    box-shadow: 2px 2px 2px rgba(29, 29, 31, 0.6);
    width: fit-content;
    right: 0;
  }
}
.item {
  display: flex;
  align-items: flex-end;
  // width: 20rem;
  // height: 20rem;
  padding: var(--spacer-sm) var(--spacer-xl);
  margin: var(--spacer-sm) 0;
  &:not(:last-child) {
    border-right: 1px solid var(--c-gray-lighten);
    @include for-mobile {
      border-right: 0;
      border-bottom: 1px solid var(--c-gray-lighten);
    }
  }
  span {
    color: var(--c-text);
    text-transform: uppercase;
    font-size: 2.5rem;
    font-weight: var(--font-medium);
    line-height: 1.4;
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 3px;
      background-color: #1d1d1f;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
    &:hover {
      cursor: pointer;
      &::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
    @include for-mobile {
      font-size: 1.5rem;
    }
  }
  @include for-mobile {
    padding: var(--spacer-sm) var(--spacer-sm) var(--spacer-xs) 0;
    margin: 0 var(--spacer-sm);
    min-width: 10rem !important;
    min-height: 10rem !important;
  }
}
</style>
