<template>
  <div class="work">
    <h1 class="title">Portfolio</h1>
    <div class="projects">
      <div
        v-for="(project, i) in data.projects"
        :key="i"
        class="project"
        @click.prevent="goTo(project.slug)"
      >
        <div class="project__container">
          <div class="project__container--inner">
            <div class="project__section project__section--padding">
              <div class="project__section--header">
                <h3 class="project__section--header-title">
                  {{ `${project.title} ‒` }}
                  <br />
                  {{ `${project.subtitle}` }}
                </h3>
              </div>
              <div class="project__section--content">
                <p>{{ project.short_description }}</p>
                <div
                  class="a-button"
                  @click.stop="
                    project.action.external
                      ? external(project.action.link)
                      : goTo(project.action.link)
                  "
                >
                  <span>{{ `${project.action.title} ‒` }}</span>
                </div>
              </div>
            </div>
            <div class="project__section">
              <div class="project__section--img">
                <img :src="getImage(project.info.gallery_folder)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    goTo(link) {
      this.$router.push({ name: "Project", params: { slug: link } });
    },
    external(link) {
      window.open(link);
    },
    getImage(gallery) {
      return require(`@/assets/${gallery}/cover.jpg`);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.work {
  max-width: 1400px;
  margin: 0 auto;
}
.title {
  font-family: var(--font-family-secondary);
  font-weight: var(--font-semibold);
  font-size: 4rem;
  text-align: center;
  line-height: 1.2;
  margin: var(--spacer-2xl) 0 var(--spacer-xl);
}

.projects {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.project {
  flex: 1 1 calc(50% - 3rem);
  max-width: calc(50% - 1.5rem);
  cursor: pointer;
  &:hover {
    .project__section--header-title {
      &::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
    .project__section--img img {
      width: 105%;
      height: 105%;
    }
  }
  &__container {
    overflow: hidden;
    box-shadow: 0 25px 50px -12px hsla(265.3, 20%, 10%, 35%);
    transform-origin: center top;
    width: 100%;
    min-height: 20rem;
    background: var(--c-white);
    border-radius: 20px;
    margin-bottom: 3rem;

    &--inner {
      display: flex;
      height: 30rem;
      @include for-mobile {
        flex-direction: column-reverse;
      }
    }
    @include for-mobile {
      margin: 0 var(--spacer-sm) var(--spacer-lg);
    }
  }
  &__section {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &--padding {
      padding: var(--spacer-xl);
      @include for-mobile {
        padding: var(--spacer-base);
      }
    }
    &--header {
      &-title {
        position: relative;
        width: fit-content;
        font-family: var(--font-family-secondary);
        font-size: 2rem;
        text-transform: uppercase;
        line-height: 1.1;
        font-weight: var(--font-semibold);
        color: var(--c-text);
        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          background-color: #1d1d1f;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
        }
      }
    }
    &--content {
      color: var(--c-text);
      p {
        margin: var(--spacer-lg) 0;
        font-size: 1rem;
        font-weight: var(--font-light);
        line-height: 1.3;
        max-width: 100%;
        padding: 0 var(--spacer-sm) 0 0;
        @include for-mobile {
          margin: var(--spacer-xs) 0 var(--spacer-base);
        }
      }
    }
    &--img {
      position: relative;
      color: var(--c-text);
      width: 100%;
      height: 100%;
      overflow: hidden;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 101%;
        height: 101%;
        object-fit: cover;
        transform: translate3d(-50%, -50%, 1px);
        transition: 0.5s ease;
      }
    }
  }
  @include for-mobile {
    flex: 1 1 calc(100% - 2rem);
    max-width: calc(100% - 2rem);
  }
}

.a-button {
  user-select: none;
  width: fit-content;
  outline: none;
  border: 1px solid var(--c-gray-variant);
  border-radius: 50px;
  padding: 0.65rem var(--spacer-base);
  background: none;
  transition: 0.5s ease;
  &:hover {
    border-color: var(--c-dark);
  }
  span {
    display: block;
    width: fit-content;
    margin: 0 auto;
    position: relative;
    font-size: 0.75rem;
    text-transform: uppercase;
    transition: transform 0.25s ease-out;
    line-height: 1.2;
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      background-color: #1d1d1f;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
    &:hover {
      cursor: pointer;
      &::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
}
.invert {
  &:hover {
    border-color: var(--c-white) !important;
  }
  &::after {
    background-color: var(--c-white) !important;
  }
}
</style>
