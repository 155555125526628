<template>
  <div class="home">
    <div class="marquee">
      <div class="marquee__group">
        <span class="paragraph"> {{ data.user.marquee }}&nbsp; </span>
      </div>
      <div class="marquee__group">
        <span class="paragraph"> {{ data.user.marquee }}&nbsp; </span>
      </div>
    </div>
    <MSection>
      <template #left>
        <div class="profile profile__description">
          <span>
            {{ data.user.short_description }}
          </span>
          <AButton :title="'A bit about me'" @click.native="goToAbout()" />
        </div>
      </template>
      <template #right>
        <div class="profile profile__image">
          <img src="@/assets/profile/laurentiututu.jpg" />
        </div>
      </template>
    </MSection>

    <OAboutMe ref="#about" />
    <OServices />
    <OProjects />
  </div>
</template>
<script>
import MSection from "@/components/molecules/m-section";
import OAboutMe from "@/components/organisms/o-about-me";
import OServices from "@/components/organisms/o-services";
import OProjects from "@/components/organisms/o-projects";
import AButton from "@/components/atoms/a-button";

export default {
  name: "Home",
  components: {
    MSection,
    OAboutMe,
    OServices,
    OProjects,
    AButton,
  },
  mounted() {
    this.checkHash();
  },
  watch: {
    $route(to, from) {
      if (to.hash) {
        if (to.hash == "#about") {
          this.$refs["#about"].$el.scrollIntoView({ behavior: "smooth" });
        }
      }
    },
  },
  methods: {
    checkHash() {
      if (this.$route.hash) {
        if (this.$route.hash == "#about") {
          this.$refs["#about"].$el.scrollIntoView({ behavior: "smooth" });
        }
      }
    },
    goToAbout() {
      this.$refs["#about"].$el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.home {
  max-width: 100%;
}
.marquee {
  user-select: none;
  display: flex;
  overflow: hidden;
  height: 56vh;
  padding: 0px;
  margin-bottom: -64px;
  &__group {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 0rem;
    min-width: 100%;
    animation: scroll 60s linear infinite;

    span {
      font-family: var(--font-family-secondary);
      font-size: 24vw;
      letter-spacing: 0px;
      font-weight: 500;
      white-space: nowrap;
    }
  }
  @include for-mobile {
    height: 14rem;
  }
}
.profile {
  width: 100%;
  height: 100vh;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__description {
    transition: 0.5s ease;
    background: var(--c-light);
    padding: var(--spacer-xl);
    color: var(--c-text);
    font-size: 2.5rem;
    font-weight: var(--font-normal);
    line-height: 1.4;
    &--button {
      width: 10rem;
      outline: none;
      border: 1px solid;
      border-radius: 50px;
      padding: 0.75rem 0;
      background: none;
      span {
        display: block;
        width: fit-content;
        margin: 0 auto;
        position: relative;
        font-size: var(--font-base);
        text-transform: uppercase;
        transition: transform 0.25s ease-out;
        line-height: 1.2;
        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 1px;
          background-color: #1d1d1f;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
        }
        &:hover {
          cursor: pointer;
          &::after {
            transform: scaleX(1);
            transform-origin: bottom left;
          }
        }
      }
    }
    span {
      display: block;
    }
    @include for-mobile {
      font-size: var(--font-xl);
      padding: var(--spacer-base);
      max-height: 20rem;
    }
  }
  &__image {
    position: relative;
    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      transform: translate3d(0, -50px, 1px);
      transition: 1s ease;
      // &:hover {
      //   width: 102%;
      //   min-height: 102%;
      // }
      @include for-mobile {
        width: 105%;
      }
    }
    @include for-mobile {
      max-height: 32rem;
    }
  }
}

.dark {
  .profile {
    &__description {
      background: var(--c-white);
    }
  }
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 0.1rem));
  }
}
</style>
