<template>
	<div class="project">
		<h1 class="title">{{ currentProject.title }}</h1>
		<div class="info">
			<div
				v-for="(item, i) in info"
				:key="i"
				class="info__item"
			>
				<h4 class="info__item--title">{{ item.title }}</h4>
				<p
					class="info__item--description"
				>
					<span
						@click="item.info.key == 'website' ? external(currentProject.info[item.key]) : ''"
					>
						{{ currentProject.info[item.key] }}
					</span>
					&nbsp;<img v-if="item.key == 'website'" src="@/assets/icons/external-link.png">
				</p>
			</div>
		</div>

		<div class="gallery">
			<div class="gallery__single">
				<img :src="getImage('image1')">
			</div>
			<div class="gallery__group">
				<div class="gallery__group--image">
					<img :src="getImage('image2')">
				</div>
				<div class="gallery__group--image">
					<img :src="getImage('image3')">
				</div>
			</div>
		</div>

		<div class="description">
			<p v-html="currentProject.description" />
		</div>

		<div class="gallery">
			<div class="gallery__group">
				<div class="gallery__group--image">
					<img :src="getImage('image4')">
				</div>
				<div class="gallery__group--image">
					<img :src="getImage('image5')">
				</div>
			</div>
			<div class="gallery__single">
				<img :src="getImage('image6')">
			</div>
		</div>
	</div>
</template>
<script>

export default {
	data () {
		return {
			info: [
				{
					title: 'Made for',
					key: 'made_for'
				},
				{
					title: 'Date',
					key: 'date'
				},
				{
					title: 'Technologies',
					key: 'role'
				},
				{
					title: 'Website',
					key: 'website'
				}
			]
		}
	},
	mounted () {
		window.scrollTo(0,0);
	},
	computed: {
		currentProject () {
			return this.data.projects.find(project => project.slug === this.$route.params.slug)
		}
	},
	methods: {
		external (link) {
			window.open(`http://${link}`, '_blank', 'noreferrer')
		},
		getImage (image) {
			return require(`@/assets/${this.currentProject.info.gallery_folder}/${image}.jpg`)
		}
	}
}
</script>
<style lang="scss" scoped>

.project {
	max-width: 1400px;
	margin: 0 auto;
}

.title {
	font-family: var(--font-family-secondary);
	font-weight: var(--font-semibold);
	font-size: 8rem;
}
.description {
	max-width: 80%;
	margin: 0 auto;
	p {
		font-size: 1.875rem;
		font-weight: var(--font-normal);
		line-height: 1.3;
		margin: 0;
	}
}
.dark {
	.info__item--description img {
		filter: invert(1);
	}
}
.info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	&__item {
		&:last-child {
			text-align: right;
			.info__item--description span {
				position: relative;
				cursor: pointer;
				&::after {
		      content: '';
		      display: block;
		      position: absolute;
		      width: 100%;
		      transform: scaleX(0);
		      height: 1px;
		      background-color: var(--c-white);
		      transform-origin: bottom right;
		      transition: transform 0.25s ease-out;
		    }
		    &:hover {
		      &::after {
		        transform: scaleX(1);
		        transform-origin: bottom left;
		      }
				}
			}
		}
		&--title {
			color: var(--c-text-disabled);
			font-weight: var(--font-medium);
			text-transform: uppercase;
			font-size: 1rem;
			margin-bottom: var(--spacer-xs);
		}
		&--description {
			font-weight: var(--font-medium);
			font-size: 1.125rem;

			img {
				width: 1rem;
				height: 1rem;
				transition: .5s ease;
			}
		}
	}
}
.gallery {
	margin: var(--spacer-2xl) 0;
	&__single {
		position: relative;
		width: 100%;
		height: 65vh;
		border-radius: 20px;
		overflow: hidden;
		margin: 2% 0;
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			min-height: 100%;
			object-fit: cover;
			transform: translate3d(0, 0, 1px);
		}
	}
	&__group {
		display: flex;
		align-items: center;
		justify-content: space-between;
		&--image {
			position: relative;
			flex: 1 1 49%;
			max-width: 49%;
			height: 90vh;
			border-radius: 20px;
			overflow: hidden;
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				transform: translate3d(0, 0, 1px);
			}
		}
	}
}
</style>