<template>
  <MSection class="o-about-me" :divider="true">
    <template #left>
      <div class="left">
        <span>
          About me
        </span>
      </div>
    </template>
    <template #right>
      <div class="right">
        <span v-for="(paragraph, i) in data.user.about_me" :key="i">{{
          paragraph
        }}</span>
      </div>
    </template>
  </MSection>
</template>
<script>
import MSection from "@/components/molecules/m-section";

export default {
  components: {
    MSection,
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.o-about-me {
  .left {
    span {
      font-family: var(--font-family-secondary);
      font-size: 4rem;
      font-weight: var(--font-semibold);
      text-transform: uppercase;
      line-height: 1;
      @include for-mobile {
        font-size: 3rem;
      }
    }
  }
  .right {
    span {
      display: block;
      font-size: 2rem;
      line-height: 1.4;
      margin-bottom: var(--spacer-base);
      @include for-mobile {
        font-size: var(--font-xl);
      }
    }
  }
}
</style>
