<template>
  <div class="o-projects projects">
    <div
      v-for="(project, i) in data.highlight_projects"
      :key="i"
      class="project"
    >
      <div class="project__container">
        <div class="project__container--inner">
          <div class="project__section project__section--padding">
            <div class="project__section--header">
              <h3 class="project__section--header-title">
                {{ `${project.title} ‒` }}
                <br />
                {{ `${project.subtitle}` }}
              </h3>
              <div class="project__section--header-perks">
                <span v-for="(perk, j) in project.perks" :key="j">{{
                  perk
                }}</span>
              </div>
            </div>
            <div class="project__section--content">
              <p>{{ project.description }}</p>
              <AButton
                :title="`${project.action.title} ‒`"
                @click.native="
                  project.action.external
                    ? external(project.action.link)
                    : goTo(project.action.link)
                "
              />
            </div>
          </div>
          <div class="project__section">
            <div class="project__section--img">
              <img :src="getImage(project.gallery_folder)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ScrollObserver, valueAtPercentage } from "aatjs";
import AButton from "@/components/atoms/a-button";

export default {
  components: {
    AButton,
  },
  mounted() {
    this.stackCards();
  },
  methods: {
    goTo(link) {
      this.$router.push(link);
    },
    external(link) {
      window.open(link);
    },
    getImage(gallery) {
      return require(`@/assets/${gallery}/cover.jpg`);
    },
    stackCards() {
      const cardsContainer = document.querySelector(".projects");
      const cards = document.querySelectorAll(".project");
      cardsContainer.style.setProperty("--projects-count", cards.length);
      cardsContainer.style.setProperty(
        "--project-height",
        `${cards[0].clientHeight}px`
      );
      Array.from(cards).forEach((card, index) => {
        const offsetTop = 20 + index * 10;
        card.style.paddingTop = `${offsetTop}px`;
        if (index === cards.length - 1) {
          return;
        }
        const toScale = 1 - (cards.length - 1 - index) * 0.025;
        const nextCard = cards[index + 1];
        const projectContainer = card.querySelector(".project__container");
        const projectInner = card.querySelector(".project__container--inner");
        ScrollObserver.Element(nextCard, {
          offsetTop,
          offsetBottom: window.innerHeight - card.clientHeight,
        }).onScroll(({ percentageY }) => {
          projectContainer.style.scale = valueAtPercentage({
            from: 1,
            to: toScale,
            percentage: percentageY,
          });
          projectContainer.style.filter = `brightness(${valueAtPercentage({
            from: 1,
            to: 0.75,
            percentage: percentageY,
          })})`;
          projectInner.style.filter = `blur(${valueAtPercentage({
            from: 0,
            to: 1,
            percentage: percentageY,
          })}px)`;
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.dark {
  .project {
    &__section {
      &--header {
        &-perks {
          span {
            border: 1px solid var(--c-dark);
            background: var(--c-dark);
            &:nth-child(2) {
              border: 1px solid var(--c-gray-lighten);
              background: var(--c-gray-lighten);
            }
            &:nth-child(3) {
              background: transparent;
              border: 1px solid var(--c-text);
              color: var(--c-text);
            }
          }
        }
      }
    }
  }
}

.o-projects {
  max-width: 1400px;
  margin: var(--spacer-xl) auto;
  padding: var(--spacer-base) 0 var(--spacer-lg);
}

.projects {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-rows: repeat(var(--projects-count), var(--project-height));
  gap: 40px 0;
}
.project {
  position: sticky;
  top: var(--spacer-base);
  transform-origin: center top;

  &__container {
    will-change: transform;
    overflow: hidden;
    box-shadow: 0 25px 50px -12px hsla(265.3, 20%, 10%, 35%);
    transform-origin: center top;
    width: 100%;
    min-height: 20rem;
    background: var(--c-white);
    border-radius: 20px;
    margin-bottom: 4rem;

    &--inner {
      display: flex;
      flex-wrap: wrap;
      height: 80vh;
      @include for-mobile {
        flex-direction: column-reverse;
        height: 45rem;
      }
    }
    @include for-mobile {
      width: calc(100% - 2rem);
      margin: 0 var(--spacer-sm) var(--spacer-lg);
    }
  }
}

.project {
  &__section {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &--padding {
      padding: var(--spacer-xl);
      @include for-mobile {
        padding: var(--spacer-base);
      }
    }
    &--header {
      &-title {
        font-family: var(--font-family-secondary);
        font-size: 4rem;
        text-transform: uppercase;
        line-height: 1.1;
        font-weight: var(--font-semibold);
        color: var(--c-text);
        @include for-mobile {
          font-size: 2rem;
        }
      }
      &-perks {
        user-select: none;
        display: flex;
        margin-top: var(--spacer-base);
        span {
          transition: 0.3s ease;
          margin-right: var(--spacer-xs);
          border-radius: 50px;
          border: 1px solid var(--c-light);
          background: var(--c-light);
          padding: var(--spacer-2xs) var(--spacer-sm);
          font-size: var(--font-sm);
          &:hover {
            transform: rotate(-3deg);
          }
          &:nth-child(2) {
            border: 1px solid var(--c-light-darken);
            background: var(--c-light-darken);
          }
          &:nth-child(3) {
            background: transparent;
            border: 1px solid var(--c-text);
            color: var(--c-text);
          }
        }
      }
    }
    &--content {
      color: var(--c-text);
      p {
        margin: var(--spacer-lg) 0;
        font-size: 1.25rem;
        font-weight: var(--font-light);
        line-height: 1.3;
        max-width: 80%;
        padding: 0 var(--spacer-sm) 0 0;
        @include for-mobile {
          font-size: var(--font-base);
          max-width: 100%;
          margin: var(--spacer-base) 0;
          padding: 0;
        }
      }
    }
    &--img {
      position: relative;
      color: var(--c-text);
      width: 100%;
      height: 100%;
      overflow: hidden;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 101%;
        height: 101%;
        object-fit: cover;
        transform: translate3d(-50%, -50%, 1px);
        transition: 0.5s ease;
        cursor: pointer;
        &:hover {
          width: 105%;
          height: 105%;
        }
      }
    }
  }
}
</style>
